<template>
    <div class="tw-min-h-fit tw-h-screen tw-flex tw-flex-col">
        <v-container class="tw-max-w-screen-xl tw-mx-auto tw-flex-1 tw-flex tw-flex-col">
            <header>
                <div class="tw-flex tw-items-center">
                    <img class="tw-w-[50px] tw-h-[50px] tw-mb-2" src="assets/images/logo.png">
                    <span class="tw-text-primary-700 tw-font-bold tw-text-xl ">Doula Care</span>
                </div>
            </header>
            <div class="tw-grid tw-grid-cols-12 tw-flex-1 ">
                <div class="tw-p-5 tw-h-full tw-col-span-12 lg:tw-col-span-6 tw-text-center lg:tw-text-start tw-flex tw-justify-center tw-flex-col">
                    <h1 class="lg:tw-text-6xl tw-text-4xl tw-mb-2 tw-font-bold tw-text-primary-600">DOULA CARE PRO</h1>
                    <p class="lg:tw-text-xl tw-text-primary-500">A state-of-the-art digital assistant created to guide women throughout their pre-conception, pregnancy, and postpartum journey.</p>
                </div>
                <div class="tw-col-span-12 lg:tw-col-span-6 tw-h-full tw-flex tw-items-center tw-justify-center">
                    <div class="md:tw-w-[70%] tw-w-full tw-h-fit tw-bg-[#EAE1EA] tw-rounded-2xl md:tw-p-10 tw-p-5">
                        <div class="tw-grid tw-grid-cols-12 tw-gap-5">

                            <div class="tw-col-span-12 md:tw-col-span-6 tw-relative">
                                <span class="tw-absolute tw-left-3 tw-top-1/2 -tw-translate-y-1/2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                        <path d="M8.06567 0C5.84761 0 4.03284 2.25839 4.03284 5.04105C4.03284 7.8237 5.84761 10.0821 8.06567 10.0821C10.2837 10.0821 12.0985 7.8237 12.0985 5.04105C12.0985 2.25839 10.2837 0 8.06567 0ZM3.85136 10.0821C1.71396 10.1829 0 11.9372 0 14.1149V16.1313H16.1313V14.1149C16.1313 11.9372 14.4376 10.1829 12.28 10.0821C11.1911 11.3121 9.69897 12.0985 8.06567 12.0985C6.43237 12.0985 4.94023 11.3121 3.85136 10.0821Z" fill="#2B3434"/>
                                    </svg>
                                </span>
                                <input type="text" placeholder="First Name" class="tw-pl-10 tw-h-[45px] tw-border-transparent tw-w-full tw-border tw-border-b-primary-950 tw-border-solid focus:tw-border-primary-950 tw-duration-200 tw-pr-2 tw-outline-none">
                            </div>
                            
                            <div class="tw-col-span-12 md:tw-col-span-6 tw-relative">
                                <span class="tw-absolute tw-left-3 tw-top-1/2 -tw-translate-y-1/2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                        <path d="M8.06567 0C5.84761 0 4.03284 2.25839 4.03284 5.04105C4.03284 7.8237 5.84761 10.0821 8.06567 10.0821C10.2837 10.0821 12.0985 7.8237 12.0985 5.04105C12.0985 2.25839 10.2837 0 8.06567 0ZM3.85136 10.0821C1.71396 10.1829 0 11.9372 0 14.1149V16.1313H16.1313V14.1149C16.1313 11.9372 14.4376 10.1829 12.28 10.0821C11.1911 11.3121 9.69897 12.0985 8.06567 12.0985C6.43237 12.0985 4.94023 11.3121 3.85136 10.0821Z" fill="#2B3434"/>
                                    </svg>
                                </span>
                                <input type="text" placeholder="Last Name" class="tw-pl-10 tw-h-[45px] tw-border-transparent tw-w-full tw-border tw-border-b-primary-950 tw-border-solid focus:tw-border-primary-950 tw-duration-200 tw-pr-2 tw-outline-none">
                            </div>

                            <div class="tw-col-span-12 tw-relative">
                                <span class="tw-absolute tw-left-3 tw-top-1/2 -tw-translate-y-1/2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                        <path d="M0.000244141 0V2L6.50024 6L13.0002 2V0H0.000244141ZM0.000244141 4V12H13.0002V4L6.50024 8L0.000244141 4Z" fill="#2B3434"/>
                                    </svg>
                                </span>
                                <input type="email" placeholder="Email" class="tw-pl-10 tw-h-[45px] tw-border-transparent tw-w-full tw-border tw-border-b-primary-950 tw-border-solid focus:tw-border-primary-950 tw-duration-200 tw-pr-2 tw-outline-none">
                            </div>

                            <div class="tw-col-span-12 tw-relative">
                                <span class="tw-absolute tw-left-3 tw-top-1/2 -tw-translate-y-1/2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" fill="none">
                                        <path d="M6.50024 0C8.88358 0 10.8336 1.8 10.8336 4H8.66691C8.66691 2.88 7.71358 2 6.50024 2C5.28691 2 4.33358 2.88 4.33358 4V8H13.0002V16H0.000244141V8H2.16691V4C2.16691 1.8 4.11691 0 6.50024 0Z" fill="#2B3434"/>
                                    </svg>
                                </span>
                                <input type="password" placeholder="Password" class="tw-pl-10 tw-h-[45px] tw-border-transparent tw-w-full tw-border tw-border-b-primary-950 tw-border-solid focus:tw-border-primary-950 tw-duration-200 tw-pr-2 tw-outline-none">
                            </div>

                            <div class="tw-col-span-12 tw-relative tw-flex tw-justify-center tw-items-center tw-py-3">
                                <button class="tw-text-white hover:tw-bg-primary-600 tw-mx-auto tw-w-fit tw-bg-primary-500 tw-duration-200 tw-border-2 tw-border-solid tw-border-primary-500 tw-rounded-xl tw-px-12 tw-py-3 tw-font-bold">
                                    Sign Up
                                </button>
                            </div>

                            <div class="tw-col-span-12 tw-px-5 tw-text-center">
                                <p class="tw-text-primary-800">Already have an account ? <router-link class="tw-text-[#30D5C8] tw-font-bold" to="/">Sign in here</router-link></p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </v-container>
        <div class="tw-bg-neutral-900 md:tw-py-2 tw-p-5 tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-items-center tw-gap-5 md:tw-gap-10">
            <a href="#" class="tw-text-white tw-font-semibold tw-underline hover:tw-underline-offset-2 tw-duration-200">About us</a>
            <a href="#" class="tw-text-white tw-font-semibold tw-underline hover:tw-underline-offset-2 tw-duration-200">Trust</a>
            <a href="#" class="tw-text-white tw-font-semibold tw-underline hover:tw-underline-offset-2 tw-duration-200">Support</a>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>