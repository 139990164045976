<template>
  <v-app-bar
    height="50"
    elevation="0"
    class="tw-border-none !tw-bg-primary-300"
  >
    <div class="tw-w-full tw-h-full tw-flex tw-justify-center tw-gap-5">
      <button
      @click="active = 1"
        :class="[active == 1 && '!tw-bg-primary-700']"
        class="tw-px-4 tw-pb-1 tw-bg-primary-900 tw-h-fit tw-rounded-b-2xl tw-duration-200"
      >
        <img src="assets/images/header-icon-1.png" class="tw-w-[45px]" alt="" />
      </button>
      <button
      @click="active = 2"
        :class="[active == 2 && '!tw-bg-primary-700']"
        class="tw-px-4 tw-pb-1 tw-bg-primary-900 tw-h-fit tw-rounded-b-2xl tw-duration-200"
      >
        <img src="assets/images/header-icon-2.png" class="tw-w-[45px]" alt="" />
      </button>
      <button
      @click="active = 3"
        :class="[active == 3 && '!tw-bg-primary-700']"
        class="tw-px-4 tw-pb-1 tw-bg-primary-900 tw-h-fit tw-rounded-b-2xl tw-duration-200"
      >
        <img src="assets/images/header-icon-3.png" class="tw-w-[45px]" alt="" />
      </button>
    </div>
  </v-app-bar>
</template>
<script setup>
import { ref } from "vue";
const active = ref(1);
</script>
