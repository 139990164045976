<template>
  <div>
    <v-container class="tw-mx-auto tw-max-w-[1200px]">
        <div class="lg:tw-p-8 tw-p-5 lg:tw-border-4 tw-rounded-lg tw-border-primary-600">
            <h1 class="lg:tw-text-3xl tw-text-2xl tw-text-primary-600 tw-text-center tw-font-bold">Search quality-assured health information in our blog</h1>

            <div class="tw-mt-5 lg:tw-px-16">
                <p class="tw-text-primary-600 tw-text-lg tw-font-bold">Search</p>
                <div class="tw-flex lg:tw-flex-row tw-flex-col  tw-items-center tw-gap-5 tw-pt-2">
                    <input placeholder="What are you looking for ?" type="text" class="placeholder:tw-font-bold placeholder:tw-text-primary-600 placeholder:tw-text-lg tw-px-5 tw-outline-none tw-border-2 tw-border-gray-700 tw-h-[50px] tw-border-solid tw-rounded-xl tw-w-full">
                    <button class="hover:tw-text-white hover:tw-bg-primary-600 tw-duration-200 tw-text-lg tw-font-bold tw-text-primary-600 tw-border-solid tw-px-6 tw-h-[50px] tw-rounded-xl tw-border-2 tw-border-primary-600">
                        Search
                    </button>
                </div>

            </div>
        </div>
    </v-container>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>