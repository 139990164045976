<template>
  <div>
    <h1 class="tw-text-4xl tw-font-bold tw-text-primary-600 tw-text-center tw-py-5 tw-mb-5">Our features</h1>

    <div class="tw-bg-[#CEEFEF] tw-min-h-[500px] tw-relative">

        <div class="tw-absolute tw-top-0 tw-left-0 tw-py-1 tw-w-[400px] tw-flex tw-items-center tw-justify-center -tw-translate-x-1/2 lg:-tw-translate-y-1/3 -tw-translate-y-[10%]">
            <div class="tw-w-full tw-aspect-square tw-rounded-full tw-bg-[#BDEAEB] tw-z-10 tw-flex tw-items-center tw-justify-center" >
                <div class="tw-w-5/6 tw-aspect-square tw-rounded-full tw-bg-[#A1E0E1] tw-z-20 tw-flex tw-items-center tw-justify-center" >
                    <div class="tw-w-4/6 tw-aspect-square tw-rounded-full tw-bg-[#89CFD0] tw-z-20 tw-flex tw-items-center tw-justify-center" >
                    </div>
                </div>
            </div>
        </div>

        <div class="tw-scale-75 tw-absolute tw-bottom-0 tw-right-0 tw-py-1 tw-w-[400px] tw-flex tw-items-center tw-justify-center tw-translate-x-1/2 tw-translate-y-1/3">
            <div class="tw-w-full tw-aspect-square tw-rounded-full tw-bg-[#BDEAEB] tw-z-10 tw-flex tw-items-center tw-justify-center" >
                <div class="tw-w-5/6 tw-aspect-square tw-rounded-full tw-bg-[#A1E0E1] tw-z-20 tw-flex tw-items-center tw-justify-center" >
                    <div class="tw-w-4/6 tw-aspect-square tw-rounded-full tw-bg-[#89CFD0] tw-z-20 tw-flex tw-items-center tw-justify-center" >
                    </div>
                </div>
            </div>
        </div>

        <v-container class="tw-relative tw-z-10 tw-mx-auto tw-max-w-[1200px]">
            <div class="tw-grid tw-grid-cols-12 tw-gap-5 tw-py-16">
                <div class="tw-col-span-12 md:tw-col-span-4 tw-bg-white tw-rounded-xl tw-p-5 hover:-tw-translate-y-[5px] tw-border-2 tw-border-transparent hover:tw-border-primary-600 tw-duration-200 hover:tw-shadow">
                    <img src="/assets/images/feature-1.png" class="tw-w-[180px] tw-h-[80px] tw-object-cover tw-mx-auto" alt="">

                    <p class="tw-text-xl tw-text-primary-600 tw-mt-5 tw-text-justify">
                        A state-of-the-art digital assistant created to guide you throughout your pre-conception, pregnancy, labor and postpartum journey.
                    </p>
                </div>

                <div class="tw-col-span-12 md:tw-col-span-4 tw-bg-white tw-rounded-xl tw-p-5 hover:-tw-translate-y-[5px] tw-border-2 tw-border-transparent hover:tw-border-primary-600 tw-duration-200 hover:tw-shadow">
                    <img src="/assets/images/feature-2.png" class="tw-w-[180px] tw-h-[80px] tw-object-cover tw-mx-auto" alt="">
                    <p class="tw-text-xl tw-text-primary-600 tw-mt-5 tw-text-justify">
                        is a comprehensive suite of resources designed to guide women through every stage of motherhood.
                    </p>
                </div>

                <div class="tw-col-span-12 md:tw-col-span-4 tw-bg-white tw-rounded-xl tw-p-5 hover:-tw-translate-y-[5px] tw-border-2 tw-border-transparent hover:tw-border-primary-600 tw-duration-200 hover:tw-shadow">
                    <img src="/assets/images/feature-3.png" class="tw-w-[180px] tw-h-[80px] tw-object-cover tw-mx-auto" alt="">
                    <p class="tw-text-xl tw-text-primary-600 tw-mt-5 tw-text-justify">
                        Empower Your Knowledge blog is a resource hub curated by our scientists, offering expert tips and articles for mothers at every stage.
                    </p>
                </div>

                <div class=" tw-col-span-12 tw-flex tw-items-center tw-justify-center tw-mt-5">
                    <button class="tw-bg-white tw-text-primary-700 tw-border-2 tw-border-solid tw-border-primary-700 tw-rounded-xl tw-px-8 tw-py-2 tw-font-bold">
                        Join the community
                    </button>
                </div>
            </div>

            
        </v-container>


    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>