<template>
    <v-layout :full-height="true" class="rounded rounded-md">
      <AppHeader />

      <v-main class="tw-pt-0">
        <router-view></router-view>
        
        <AppFooter />
      </v-main>
    </v-layout>
  </template>
  
  <script>
import AppFooter from './AppFooter.vue';
import AppHeader from './AppHeader.vue';

  export default {
    components: { AppHeader, AppFooter }
}
  </script>
  
  <style>
  
  </style>