<template>
    <div>

        <div class="tw-mx-auto  tw-h-screen tw-flex tw-flex-col">
            <div class="tw-flex-1"></div>
            <div class="tw-grid tw-grid-cols-12  tw-relative">
                    <div class="tw-col-span-6 tw-h-full tw-relative tw-overflow-hidden">
                        <div class="tw-absolute tw-bottom-0 tw-left-0 tw-py-1 tw-w-full tw-scale-[1.6] tw-max-w-[650px]">
                            <div class="tw-w-2/5 tw-max-w-[500px] tw-aspect-square tw-rounded-full tw-bg-primary-600 tw-absolute -tw-bottom-10 tw-left-10 tw-z-10" >
                            </div>
                            <div class="tw-w-3/6 tw-max-w-[350px] tw-aspect-square tw-rounded-full tw-bg-primary-600/70 tw-absolute -tw-bottom-10 tw-left-10 tw-z-20" >
                            </div>
                            <div class="tw-w-4/6 tw-max-w-[400px] tw-aspect-square tw-rounded-full tw-bg-primary-600/40 tw-absolute -tw-bottom-10 tw-left-10 tw-z-20" >
                            </div>
                        </div>

                        <div class="tw-relative tw-z-10 lg:tw-h-[600px] 2xl:tw-h-[750px] tw-pt-[40px]">
                            <img class="tw-max-h-full 2xl:tw-max-w-lg  tw-object-contain tw-object-bottom tw-w-full tw-h-full" src="assets/images/woman-1.webp" alt="Pregnant Woman">
                        </div>
                    </div>

                    <div class="tw-col-span-6 tw-flex tw-pt-16 tw-flex-col 2xl:tw-px-10 lg:tw-pr-16">
                        <h1 class="md:tw-text-6xl tw-text-3xl 2xl:tw-text-7xl tw-text-primary-700 tw-font-bold tw-mb-10 tw-mt-16">DOULA CARE</h1>
                        <p class="tw-text-xl 2xl:tw-text-3xl tw-font-light tw-text-primary-600">A state-of-the-art digital assistant created to guide women throughout their pre-conception, pregnancy, and postpartum journey.</p>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {

}
</script>

<style>

</style>