<template>
    <div class="tw-min-h-fit tw-h-[calc(100dvh-50px)] tw-flex tw-flex-col tw-relative md:tw-pb-[100px] tw-pb-[125px]">
        <div id="chat-container" class="md:tw-p-10 tw-p-5 tw-pb-0 tw-flex tw-flex-col-reverse md:tw-px-20 tw-h-full  tw-w-full tw-overflow-auto tw-mx-auto">
            <!-- <div class="tw-max-w-[900px] tw-mx-auto tw-flex tw-flex-col-reverse tw-h-full tw-overflow-y-scroll"> -->
                <chat-message v-for="(m) in messages" :key="m.content" :message="m" />
            <!-- </div> -->
        </div>

        <chat-input />
    </div>
</template>
<script setup>
import ChatMessage from "@/components/chat/ChatMessage.vue";
import ChatInput from "@/components/chat/ChatInput.vue";
import { useChatStore } from '@/stores/chat';
import { computed } from "vue";

const chat = useChatStore();
const messages = computed(() => chat.messages.toReversed());

// chat.setMessages(initialMessages);



</script>