<template>
  <v-app-bar class="tw-overflow-visible" height="80" elevation="0" color="transparent" border="0" :absolute="true">
        <v-container>
          <div class="tw-flex tw-items-center">
            <div class="tw-flex tw-items-center">
              <img class="tw-w-[50px] tw-h-[50px] tw-mb-2" src="assets/images/logo.png">
              <span class="tw-text-primary-700 tw-font-bold tw-text-xl ">Doula Care</span>
            </div>

            <div class="tw-flex-1 md:tw-hidden tw-block"></div>

            <div class="md:tw-ml-16">
              <AppHeaderMenu />
            </div>

            <div class="tw-flex-1 md:tw-block tw-hidden"></div>

            <div class="tw-items-center tw-gap-2 md:tw-flex tw-hidden">

              <button class="hover:tw-text-white hover:tw-bg-primary-600 tw-duration-200 tw-text-primary-700 tw-border-2 tw-border-solid tw-border-primary-700 tw-rounded-xl tw-px-8 tw-py-2 tw-font-bold">
                Log in
              </button>

              <button class="hover:tw-text-white hover:tw-bg-primary-800 tw-duration-200 tw-bg-primary-700 tw-text-white tw-border-2 tw-border-solid tw-border-primary-700 tw-rounded-xl tw-px-8 tw-py-2 tw-font-bold">
                Join us
              </button>
            </div>
        </div>
        </v-container>
      </v-app-bar>
</template>

<script>
import AppHeaderMenu from './AppHeaderMenu.vue'
export default {
  components: { AppHeaderMenu },

}
</script>

<style>

</style>