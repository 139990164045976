import { useChatStore } from "@/stores/chat"

export const sendMessage = (content) => {
    const store = useChatStore();

    const message = {
        from: 'Me',
        content: content,
        position: 'right'
    }

    store.addMessage(message)
}