<template>
  <div>
      <!-- <div class="tw-mt-16"></div> -->

      <HeroSection class="tw-hidden sm:tw-block" /> 
      <HeroSectionMobile class="sm:tw-hidden" /> 

      <div class="tw-my-16"></div>

      <OurFeaturesSection />

      <div class="tw-my-16"></div>
      
      <WhyChooseUsSection />
      
      <div class="tw-my-12"></div>
      
      <SearchSection />

      <div class="tw-my-12"></div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

import HeroSection from '@/components/home/HeroSection';
import HeroSectionMobile from '@/components/home/HeroSectionMobile';
import OurFeaturesSection from '@/components/home/OurFeaturesSection.vue';
import WhyChooseUsSection from '@/components/home/WhyChooseUsSection.vue';
import SearchSection from '@/components/home/SearchSection.vue';

export default defineComponent({
  name: 'HomeView',

  components: { HeroSection, OurFeaturesSection, WhyChooseUsSection, SearchSection, HeroSectionMobile },
});
</script>
