<template>
    <button @click="drawer = !drawer" class="tw-shadow tw-absolute tw-z-10 text-white tw-text-lg tw-flex tw-items-center tw-justify-center tw-top-1/2 tw-left-0 -tw-translate-y-1/2  tw-w-6 tw-h-[150px] tw-bg-primary-900 tw-rounded-r-full">
        {{ drawer ? '<' : '>' }}
    </button>

    <v-navigation-drawer width="300" floating 
    v-model="drawer"
    class="tw-bg-primary-800 tw-text-white tw-border-none">
        <button @click="drawer = !drawer" class="tw-shadow tw-absolute tw-text-lg tw-flex tw-items-center tw-justify-center tw-top-1/2 tw-right-0 -tw-translate-y-1/2 tw-translate-x-full tw-w-6 tw-h-[150px] tw-bg-primary-900 tw-rounded-r-full">
            {{ drawer ? '<' : '>' }}
        </button>
        <div class="tw-absolute tw-bottom-0 tw-left-0 tw-w-full tw-px-5 tw-py-2 tw-bg-primary-800">
            <button class="tw-border tw-border-solid tw-border-white tw-flex tw-justify-between tw-items-center tw-w-full tw-py-2 tw-px-3 tw-rounded-lg hover:tw-bg-white/10 tw-duration-200">
                <span>Username</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 0C7.25 0 5 2.8 5 6.25C5 9.7 7.25 12.5 10 12.5C12.75 12.5 15 9.7 15 6.25C15 2.8 12.75 0 10 0ZM4.775 12.5C2.125 12.625 0 14.8 0 17.5V20H20V17.5C20 14.8 17.9 12.625 15.225 12.5C13.875 14.025 12.025 15 10 15C7.975 15 6.125 14.025 4.775 12.5Z" fill="white"/>
                </svg>
            </button>
        </div>
        <div class="tw-p-5 tw-pb-16">
            <h1 class="tw-text-xl tw-font-semibold tw-text-white">Most Asked Questions</h1>

            <div class="tw-mt-5">
                <p class="tw-text-xs">1. Fertility and timing questions</p>
                <ul class="tw-mt-1.5 tw-space-y-2">
                    <li class="tw-p-2 tw-rounded-lg tw-border tw-border-solid tw-border-white/60 hover:tw-bg-white/10 tw-duration-200 tw-cursor-pointer tw-text-sm">
                        How can I track my ovulation and fertile window?
                    </li>
                    <li class="tw-p-2 tw-rounded-lg tw-border tw-border-solid tw-border-white/60 hover:tw-bg-white/10 tw-duration-200 tw-cursor-pointer tw-text-sm">
                        What tips are there for maximizing our chances of conception each cycle?
                    </li>
                </ul>
            </div>

            <div class="tw-mt-4">
                <p class="tw-text-xs">2. Lifestyle change questions</p>
                <ul class="tw-mt-1.5 tw-space-y-2">
                    <li class="tw-p-2 tw-rounded-lg tw-border tw-border-solid tw-border-white/60 hover:tw-bg-white/10 tw-duration-200 tw-cursor-pointer tw-text-sm">
                        How can I track my ovulation and fertile window?
                    </li>
                    <li class="tw-p-2 tw-rounded-lg tw-border tw-border-solid tw-border-white/60 hover:tw-bg-white/10 tw-duration-200 tw-cursor-pointer tw-text-sm">
                        What tips are there for maximizing our chances of conception each cycle?
                    </li>
                </ul>
            </div>

            <div class="tw-mt-4">
                <p class="tw-text-xs">1. Fertility and timing questions</p>
                <ul class="tw-mt-1.5 tw-space-y-2">
                    <li class="tw-p-2 tw-rounded-lg tw-border tw-border-solid tw-border-white/60 hover:tw-bg-white/10 tw-duration-200 tw-cursor-pointer tw-text-sm">
                        How can I track my ovulation and fertile window?
                    </li>
                    <li class="tw-p-2 tw-rounded-lg tw-border tw-border-solid tw-border-white/60 hover:tw-bg-white/10 tw-duration-200 tw-cursor-pointer tw-text-sm">
                        What tips are there for maximizing our chances of conception each cycle?
                    </li>
                </ul>
            </div>
        </div>
    </v-navigation-drawer>
</template>
<script setup>
import { ref } from 'vue';

const drawer = ref(true);
</script>