import { defineStore } from "pinia";

const initialState = {
    isGenerating: false,
    messageBox: '', 
    messages: []
}

export const useChatStore = defineStore('chat', {
    state: () => initialState,

    actions: {
        setIsGenerating(v) {
            this.isGenerating = v;
        },

        setMessages(messages) {
            this.messages = messages;
        },
        
        addMessage(message) {
            this.messages.push(message);
        },

        updateMessage(id, message) {
            this.messages = this.messages.map(m => m.id == id ? message : m);
        },
    }
})