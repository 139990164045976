<template>
    <div class="tw-relative">

        <div class="tw-mx-auto  tw-h-screen tw-flex tw-flex-col tw-overflow-hidden">
            <div class="tw-flex-1"></div>
            <div class="tw-grid tw-grid-cols-12  tw-relative -tw-translate-y-[55px]">
                    <div class="tw-col-span-12 tw-h-full tw-relative tw-overflow-hidden tw-py-[90px]">
                        <div class="tw-absolute tw-bottom-0 tw-left-0 tw-py-1 tw-w-full tw-scale-[1.6] tw-max-w-[650px]">
                            <div class="tw-w-3/5 tw-scale-125 tw-max-w-[500px] tw-aspect-square tw-rounded-full tw-bg-primary-600 tw-absolute -tw-bottom-10 tw-left-10 tw-z-40" >
                            </div>
                            <div class="tw-w-5/6 tw-scale-125 -tw-translate-x-1/3 tw-max-w-[350px] tw-aspect-square tw-rounded-full tw-bg-primary-600/70 tw-absolute -tw-bottom-10 tw-left-10 tw-z-20" >
                            </div>
                            <div class="tw-w-full tw-scale-125 tw-translate-x-1/4 tw-max-w-[400px] tw-aspect-square tw-rounded-full tw-bg-[#C8A2C8] tw-absolute -tw-bottom-10 tw-left-10 tw-z-30" >
                            </div>
                        </div>

                        <div class="tw-relative tw-z-10 lg:tw-h-[600px] 2xl:tw-h-[750px]">
                            <img class="tw-max-h-full tw-translate-y-[90px] -tw-translate-x-[80px] tw-object-contain tw-w-full tw-h-full" src="assets/images/woman-1.webp" alt="Pregnant Woman">
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="tw-absolute tw-bottom-12 tw-right-0 tw-p-5 tw-pb-16 tw-z-50">
                <button class="tw-bg-white tw-text-primary-700 tw-border-2 tw-border-solid tw-border-primary-700 tw-rounded-xl tw-px-8 tw-py-2 tw-font-bold">
                    Join Us
                </button>
            </div>
        </div>
</template>

<script>
export default {

}
</script>

<style>

</style>