<template>
    <div class="tw-p-3 tw-absolute tw-bottom-0 tw-w-full tw-bg-primary-300">
        <div class="">
            <div class="tw-relative tw-max-w-[800px] tw-mx-auto">
                <input type="text" v-model="message" @keyup.enter="handleSendMessage" placeholder="Type a message"
                    class="tw-w-full tw-px-5 tw-pr-10 tw-bg-white tw-duration-200 focus:tw-border-primary-600 tw-border-solid tw-border tw-border-transparent tw-outline-none tw-h-[45px] tw-rounded-lg" />
                
                    <button v-if="!isGenerating" @click="handleSendMessage"
                        :disabled="!message"
                        :class="[!message && '!tw-bg-gray-200 !tw-stroke-gray-500']"
                        class="tw-absolute tw-right-2 tw-rounded-md hover:tw-bg-primary-600 tw-stroke-primary-600 tw-duration-200 hover:tw-stroke-white tw-p-2 tw-top-1/2 -tw-translate-y-1/2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M16 0L0 8L6 10L8 16L16 0Z" />
                        </svg>
                    </button>

                    <button v-if="isGenerating" @click="handleStopGenerating"
                        class="tw-absolute tw-right-2 tw-rounded-md hover:tw-bg-primary-600 tw-stroke-primary-600 tw-duration-200 hover:tw-stroke-white tw-p-2 tw-top-1/2 -tw-translate-y-1/2">
                    
                        <div class="tw-w-[18px] tw-h-[18px] tw-rounded tw-bg-primary-300"></div>
                    </button>

            </div>
        </div>
        <p class="tw-text-center tw-text-[rgba(52,50,63,0.80)] tw-font-semibold tw-mt-3 tw-text-xs">
            Carie may produce inaccurate information about people, places, or facts.
        </p>
    </div>
</template>

<script setup>
import { useChatStore } from "@/stores/chat";
import { sendMessage } from "@/actions/chat/sendMessage";
import { ref, computed } from "vue";

const chat = useChatStore();
const isGenerating = computed(() => chat.isGenerating)
const message = ref("");
let count = 1;

const handleSendMessage = () => {
    sendMessage(message.value);
    message.value = "";
    addMessage(count);
};

const handleStopGenerating = () => {
    chat.setIsGenerating(false);
}

const addMessage = (id) => {
    const m = initialMessages.find((i) => i.id == id);
    if (!m) return false;

    chat.addMessage({ ...m, loading: true });

    setTimeout(() => {
        chat.updateMessage(id, { ...m, loading: false });
    }, 3000);

    count++;
};

const initialMessages = [
    {
        id: 1,
        from: "Carie",
        content: "Hey there! What's on your mind today?",
        position: "left",
    },
    {
        id: 2,
        from: "Carie",
        content:
            "I can help answer questions, provide information, assist with tasks, offer suggestions, or just chat about almost anything you'd like! Whether you need help with a specific problem or just want to have a conversation, feel free to ask. What do you need today?",
        position: "left",
    },
];
</script>
