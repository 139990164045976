<template>
    <v-layout :full-height="true" class="rounded rounded-md !tw-bg-primary-300 !tw-overflow-hidden">

        <chat-sidebar class="tw-hidden md:tw-block" />
        
        <chat-header />

        <v-main>
            <router-view></router-view>
        </v-main>
    </v-layout>
</template>
<script setup>
import ChatSidebar from './partials/ChatSidebar.vue';
import ChatHeader from './partials/ChatHeader.vue';

document.getElementsByTagName('html')[0]
.style.overflow = 'hidden';
</script>