<template>
    <div class="tw-bg-[#EAE1EA] md:tw-bg-white">
        <div class="tw-min-h-fit md:tw-h-screen tw-flex tw-flex-col ">
        <v-container class="tw-max-w-screen-xl tw-mx-auto tw-flex-1 tw-flex tw-flex-col">
            <header>
                <div class="tw-flex tw-items-center">
                    <img class="tw-w-[50px] tw-h-[50px] tw-mb-2" src="assets/images/logo.png">
                    <span class="tw-text-primary-700 tw-font-bold tw-text-xl ">Doula Care</span>
                </div>
            </header>
            <div class="tw-grid tw-grid-cols-12 tw-flex-1 tw-py-10">
                <div class="tw-hidden tw-p-5 tw-h-full tw-col-span-12 lg:tw-col-span-5 tw-text-center lg:tw-text-start md:tw-flex tw-justify-center tw-flex-col">
                    <img src="assets/images/woman-2.png" alt="pregnant woman"
                        class="tw-max-w-[300px] 2xl:tw-max-w-[450px] tw-mx-auto"
                    />
                    <p class="lg:tw-text-md tw-mt-3 tw-text-primary-500 tw-text-center">
                        At our core, we believe that understanding your unique needs is the key to providing exceptional care. Our pregnancy app embraces this philosophy, as we strive to know you better each day. By gathering insights and personalized information, we tailor our support to your individual journey, ensuring that every aspect of our app is designed to meet your specific needs with utmost precision and compassion.
                    </p>
                </div>
                <div class="tw-col-span-12 lg:tw-col-span-7 tw-h-full tw-flex tw-items-center tw-justify-center">
                    <div class="md:tw-w-[85%] tw-w-full tw-h-fit md:tw-bg-[#EAE1EA] tw-rounded-2xl md:tw-p-5 tw-p-5">
                        <h1 class="tw-text-xl tw-font-semibold tw-text-neutral-700 tw-text-center tw-mb-4">
                            <span class="tw-text-[#30D5C8]">Carie</span> : I am here to help you , could you please share a bit about you &lt;3</h1>
                        
                            <div class="tw-grid tw-grid-cols-12 tw-gap-5 md:tw-px-10">

                            <div class="tw-col-span-12 tw-relative">
                                <select
                                class="tw-bg-white tw-w-full tw-rounded-2xl tw-px-5 tw-py-3 tw-outline-none"
                                >
                                    <option value="" selected>Age Groupe</option>
                                </select>
                                <span class="tw-absolute tw-right-5 tw-top-1/2 -tw-translate-y-1/2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
                                        <path d="M3.1875 0L0 3.22335L8.5 11.819L17 3.22335L13.8125 0L8.5 5.37226L3.1875 0Z" fill="#82838A"/>
                                    </svg>
                                </span>
                            </div>

                            <div class="tw-col-span-12 tw-relative">
                                <select
                                class="tw-bg-white tw-w-full tw-rounded-2xl tw-px-5 tw-py-3 tw-outline-none"
                                >
                                    <option value="" selected>Support Network</option>
                                </select>
                                <span class="tw-absolute tw-right-5 tw-top-1/2 -tw-translate-y-1/2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
                                        <path d="M3.1875 0L0 3.22335L8.5 11.819L17 3.22335L13.8125 0L8.5 5.37226L3.1875 0Z" fill="#82838A"/>
                                    </svg>
                                </span>
                            </div>

                            <div class="tw-col-span-12 tw-relative">
                                <select
                                class="tw-bg-white tw-w-full tw-rounded-2xl tw-px-5 tw-py-3 tw-outline-none"
                                >
                                    <option value="" selected>Expected Support</option>
                                </select>
                                <span class="tw-absolute tw-right-5 tw-top-1/2 -tw-translate-y-1/2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
                                        <path d="M3.1875 0L0 3.22335L8.5 11.819L17 3.22335L13.8125 0L8.5 5.37226L3.1875 0Z" fill="#82838A"/>
                                    </svg>
                                </span>
                            </div>

                            <div class="tw-col-span-6 md:tw-col-span-6 tw-relative">
                                <select
                                class="tw-bg-white tw-w-full tw-rounded-2xl tw-px-5 tw-py-3 tw-outline-none"
                                >
                                    <option value="" selected>Living Status</option>
                                </select>
                                <span class="tw-absolute tw-right-5 tw-top-1/2 -tw-translate-y-1/2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
                                        <path d="M3.1875 0L0 3.22335L8.5 11.819L17 3.22335L13.8125 0L8.5 5.37226L3.1875 0Z" fill="#82838A"/>
                                    </svg>
                                </span>
                            </div>

                            <div class="tw-col-span-6 md:tw-col-span-6 tw-relative">
                                <select
                                class="tw-bg-white tw-w-full tw-rounded-2xl tw-px-5 tw-py-3 tw-outline-none"
                                >
                                    <option value="" selected>General Health</option>
                                </select>
                                <span class="tw-absolute tw-right-5 tw-top-1/2 -tw-translate-y-1/2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
                                        <path d="M3.1875 0L0 3.22335L8.5 11.819L17 3.22335L13.8125 0L8.5 5.37226L3.1875 0Z" fill="#82838A"/>
                                    </svg>
                                </span>
                            </div>

                            <div class="tw-col-span-6 md:tw-col-span-6 tw-relative">
                                <select
                                class="tw-bg-white tw-w-full tw-rounded-2xl tw-px-5 tw-py-3 tw-outline-none"
                                >
                                    <option value="" selected>Birth Type</option>
                                </select>
                                <span class="tw-absolute tw-right-5 tw-top-1/2 -tw-translate-y-1/2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
                                        <path d="M3.1875 0L0 3.22335L8.5 11.819L17 3.22335L13.8125 0L8.5 5.37226L3.1875 0Z" fill="#82838A"/>
                                    </svg>
                                </span>
                            </div>

                            <div class="tw-col-span-6 md:tw-col-span-6 tw-relative">
                                <select
                                class="tw-bg-white tw-w-full tw-rounded-2xl tw-px-5 tw-py-3 tw-outline-none"
                                >
                                    <option value="" selected>Martial Status</option>
                                </select>
                                <span class="tw-absolute tw-right-5 tw-top-1/2 -tw-translate-y-1/2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
                                        <path d="M3.1875 0L0 3.22335L8.5 11.819L17 3.22335L13.8125 0L8.5 5.37226L3.1875 0Z" fill="#82838A"/>
                                    </svg>
                                </span>
                            </div>

                            <div class="tw-col-span-6 md:tw-col-span-4 tw-relative">
                                <select
                                class="tw-bg-white tw-w-full tw-rounded-2xl tw-px-5 tw-py-3 tw-outline-none"
                                >
                                    <option value="" selected>Weight</option>
                                </select>
                                <span class="tw-absolute tw-right-5 tw-top-1/2 -tw-translate-y-1/2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
                                        <path d="M3.1875 0L0 3.22335L8.5 11.819L17 3.22335L13.8125 0L8.5 5.37226L3.1875 0Z" fill="#82838A"/>
                                    </svg>
                                </span>
                            </div>

                            <div class="tw-col-span-6 md:tw-col-span-4 tw-relative">
                                <select
                                class="tw-bg-white tw-w-full tw-rounded-2xl tw-px-5 tw-py-3 tw-outline-none"
                                >
                                    <option value="" selected>Status</option>
                                </select>
                                <span class="tw-absolute tw-right-5 tw-top-1/2 -tw-translate-y-1/2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
                                        <path d="M3.1875 0L0 3.22335L8.5 11.819L17 3.22335L13.8125 0L8.5 5.37226L3.1875 0Z" fill="#82838A"/>
                                    </svg>
                                </span>
                            </div>

                            <div class="tw-col-span-12 md:tw-col-span-4 tw-relative">
                                <select
                                class="tw-bg-white tw-w-full tw-rounded-2xl tw-px-5 tw-py-3 tw-outline-none"
                                >
                                    <option value="" selected>Stage</option>
                                </select>
                                <span class="tw-absolute tw-right-5 tw-top-1/2 -tw-translate-y-1/2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
                                        <path d="M3.1875 0L0 3.22335L8.5 11.819L17 3.22335L13.8125 0L8.5 5.37226L3.1875 0Z" fill="#82838A"/>
                                    </svg>
                                </span>
                            </div>
                            

                            <div class="tw-col-span-12 tw-relative tw-flex tw-justify-center tw-items-center tw-py-3">
                                <button class="tw-text-white hover:tw-bg-primary-600 tw-mx-auto tw-w-fit tw-bg-primary-500 tw-duration-200 tw-border-2 tw-border-solid tw-border-primary-500 tw-rounded-xl tw-px-12 tw-py-3 tw-font-bold">
                                    Start Journey
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-container>
        <div class="tw-bg-neutral-900 md:tw-py-2 tw-p-5 tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-items-center tw-gap-5 md:tw-gap-10">
            <a href="#" class="tw-text-white tw-font-semibold tw-underline hover:tw-underline-offset-2 tw-duration-200">About us</a>
            <a href="#" class="tw-text-white tw-font-semibold tw-underline hover:tw-underline-offset-2 tw-duration-200">Trust</a>
            <a href="#" class="tw-text-white tw-font-semibold tw-underline hover:tw-underline-offset-2 tw-duration-200">Support</a>
        </div>
    </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>