import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DashboardLayout from '@/layouts/dashboard/DashboardLayout'
import ChatLayout from '@/layouts/chat/ChatLayout'
import AppLayout from '@/layouts/app/AppLayout.vue'
import RegisterView from '@/views/auth/RegisterView'
import StartView from '@/views/StartView'
import ChatView from '@/views/ChatView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: AppLayout,
    children: [
      {
        name: 'home',
        path: '',
        component: HomeView
      }
    ]
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
  },
  {
    path: '/getting-started',
    name: 'getting-started',
    component: StartView,
  },
  {
    path: '/chat',
    component: ChatLayout,
    children: [
      {
        path: '',
        name: 'chat',
        component: ChatView
      }
    ]
  },
  {
    path: '/dashboard',
    component: DashboardLayout,
    children: [
      {
        name: 'dashboard',
        path: '',
        component: HomeView
      }
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
