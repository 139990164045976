<template>
    <div v-if="message.position == 'left'" class="tw-w-full tw-mt-1">
        <span class="tw-font-bold">{{ message.from }}</span>
        <div v-if="!message.loading" class=" tw-p-2 tw-px-5 tw-text-white tw-w-fit tw-bg-[#E789E0] tw-rounded-2xl tw-rounded-tl-none">
            {{ displayResponse }}
            <span v-if="!completedTyping">
                <svg
                    viewBox="8 4 8 16"
                    xmlns="http://www.w3.org/2000/svg"
                    class="cursor"
                    >
                    <rect x="10" y="6" width="4" height="12" fill="#fff" />
                </svg> 
            </span>
        </div>
        <div v-if="message.loading" class=" tw-p-2 tw-text-white tw-w-fit">
            <div class="tw-flex tw-items-center tw-gap-1">
                <span class="tw-w-2 tw-h-2 tw-rounded-full tw-block tw-bg-white tw-animate-pulse" style="animation-delay: 0s;"></span>
                <span class="tw-w-2 tw-h-2 tw-rounded-full tw-block tw-bg-white tw-animate-pulse" style="animation-delay: .2s;"></span>
                <span class="tw-w-2 tw-h-2 tw-rounded-full tw-block tw-bg-white tw-animate-pulse" style="animation-delay: .4s;"></span>
            </div>
        </div>
    </div>
    <div v-if="message.position == 'right'" class="tw-w-full tw-mt-1 tw-flex tw-flex-col tw-items-end">
        <span class="tw-font-bold">{{ message.from }}</span>
        <div class=" tw-p-2 tw-px-5 tw-text-white tw-w-fit tw-bg-[#38EDDF] tw-rounded-2xl tw-rounded-tr-none">
            {{ message.content }}
        </div>
    </div>
  </template>
  
<script setup>
import { defineProps, toRef, ref, watch } from 'vue';
import { useChatStore } from '@/stores/chat';

const chat = useChatStore();
const props = defineProps(['message']);
let intervalId = null;
const message = toRef(props, 'message');
const messageText = message.value.content;
const completedTyping = ref(false);
const displayResponse = ref('');
// const chatContainer = document.getElementById('chat-container');

watch(() => message.value.loading, (v) => {
    if(v || message.value.position == 'right') return false;
    
    let i = 0;
    completedTyping.value = false;
    chat.setIsGenerating(true);
    intervalId = setInterval(() => {
        displayResponse.value = messageText.slice(0, i);

        i++;
        // scrollToBottom();
        if (i > messageText.length || !chat.isGenerating) {
            clearInterval(intervalId);
            completedTyping.value = true;
            chat.setIsGenerating(false);
        }
    }, 20);

})


// const scrollToBottom = () => {
//   chatContainer.scrollTop = chatContainer.scrollHeight; 
//   window.scrollTo(0, chatContainer.innerHeight);
// }

// scrollToBottom();
</script>

<style scoped>

.cursor {
  display: inline-block;
  width: 1ch;
  animation: flicker 0.5s infinite;
  margin-bottom: 4px;
}

@keyframes flicker {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>